import React from "react"
import { Link } from "gatsby";
import { CheckIcon } from '@heroicons/react/solid'
import Layout from "../components/layout"
import Seo from '../components/seo';
import { StaticImage } from "gatsby-plugin-image";
import Issue1 from '../images/Issues/1.png';
import Issue2 from '../images/Issues/2.png';
import Issue4 from '../images/Issues/4.png';

const features = [
  {
    name: 'ACU & CPAC Award for Conservative Voting Excellence',
  },
  {
    name: 'NRA "A" Rating',
  },
  {
    name: 'Endorsed by Idaho Chooses Life',
  },
  {
    name: 'Proven Pro-Life Voting Record',
  },
  {
    name: 'Tax Cut Advocate',
  },
  {
    name: 'Anti-mask and Vaccine Mandate Voting Record',
  },
]

const issues = [
  {
    title: 'Constitutional Rights',
    img: Issue1,
    description:
      "I have vigilantly defended our God-given rights."
  },
  {
    title: 'Cut Taxes',
    description:
      "This year I was a co-sponsor of the largest tax cut in the history of Idaho. But I believe we can and should do more.",
    img: Issue2,
  },
  {
    title: 'School Choice & Parental Rights',
    description:
      "I believe that parents are the greatest form of “local control.” In education one size does not fit all.",
    img: Issue4
  },
]


const IndexPage = () => (
  <Layout>
    <Seo title="Gayann DeMordaunt" />
    <div className="sm:hidden relative overflow-hidden">
      <StaticImage
        src="../images/Gayann2Large.jpeg"
        alt="Gayann DeMordaunt"
      />
      <div className="absolute inset-0 bg-blue-100 mix-blend-multiply" />
    </div>
    <div className="sm:hidden px-4 py-12 sm:px-6 sm:py-24 lg:py-52 lg:px-8 bg-blue-900">
      <h1 className="mt-1 tracking-tight">
        <span className="block text-white font-extrabold text-2xl sm:text-5xl lg:text-5xl">Rep. Gayann DeMordaunt</span>
        <span className="block text-red-600 font-extrabold text-3xl sm:text-5xl lg:text-6xl">Proven Conservative</span>
      </h1>
      <div className="mt-6">
        <Link
          to="/issues"
          className="font-medium text-blue-50 text-lg hover:text-blue-100"
        >
          Learn About Gayann's Platform &rarr;
        </Link><br />
        <Link
          to="/biography"
          className="font-medium text-blue-50 text-lg hover:text-blue-100"
        >
          Meet Gayann &rarr;
        </Link>
      </div>
      <div className="mt-6">
        <a href="https://www.facebook.com/gayanndemordaunt" target="_blank" rel="noreferrer">
          <svg fill="currentColor" viewBox="0 0 24 24" className="h-8 w-8 text-blue-50 hover:text-blue-100">
            <path
              fillRule="evenodd"
              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
              clipRule="evenodd"
            />
          </svg>
        </a>
      </div>
    </div>
    <div className="hidden sm:block">
      <div className="relative">
        <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
        <div className="mx-auto">
          <div className="relative sm:overflow-hidden">
            <div className="absolute inset-0">
              <StaticImage
                className="h-full w-full object-cover object-top"
                src="../images/Gayann2.jpeg"
                alt="Gayann DeMordaunt"
              />
              <div className="absolute inset-0 bg-blue-200 mix-blend-multiply" />
            </div>
            <div className="relative py-16 sm:py-24 lg:py-60 grid grid-cols-2">
              <div className="col-span-1">
              </div>
              <div className="col-span-1 text-left">
                <h1 className="mt-1 tracking-tight">
                  <span className="block text-white font-extrabold text-2xl md:text-3xl xl:text-5xl">Rep. Gayann DeMordaunt</span>
                  <span className="block text-red-700 font-extrabold text-3xl md:text-4xl xl:text-6xl">Proven Conservative</span>
                </h1>
                {/* <p className="mt-6 max-w-lg mx-auto text-xl text-indigo-200 sm:max-w-3xl">
                  Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt
                  amet fugiat veniam occaecat fugiat aliqua.
                </p> */}
                <div className="mt-6 flex text-base max-w-prose mx-auto lg:max-w-none space-x-8">
                  <Link
                    to="/issues"
                    className="font-medium text-white text-lg hover:text-blue-100"
                  >
                    Learn About Gayann's Platform &rarr;
                  </Link>
                  <Link
                    to="/biography"
                    className="font-medium text-white text-lg hover:text-blue-100"
                  >
                    Meet Gayann &rarr;
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* Video Section */}
    <div className="">
      <div className="py-16 overflow-hidden lg:py-24">
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <svg
            className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-full"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-red-600" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={784} fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
          </svg>

          <div className="relative">
            <div className="text-center pb-10">
              {/* <h2 className="text-base font-bold text-blue-900 tracking-wide uppercase">What Gayann Believes</h2> */}
              <p className="mt-1 text-2xl font-bold text-red-600 sm:text-4xl sm:tracking-tight lg:text-5xl">
                Idaho Values. Idaho Strong.
              </p>
            </div>
            <div className="w-full sm:w-1/2 h-60 sm:h-96 mx-auto">
              <iframe
                src="https://www.youtube.com/embed/ml-PZrwqc08?rel=0"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                className="rounded-lg shadow-lg mx-auto w-full h-full"
              >
              </iframe>
            </div>
          </div>

          <svg
            className="hidden lg:block absolute right-full transform translate-x-1/2"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-red-600" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={784} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
          </svg>

          <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="mt-10 relative lg:mt-0" aria-hidden="true">
              {/* Mobile SVG */}
              <svg
                className="absolute left-1/2 transform -translate-x-1/2 lg:hidden"
                width={784}
                height={404}
                fill="none"
                viewBox="0 0 784 404"
              >
                <defs>
                  <pattern
                    id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-red-600" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={784} height={404} fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="">
      <div className="bg-gray-100">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-base font-bold text-blue-900 tracking-wide uppercase">Platform</h2>
            <p className="mt-1 text-2xl font-bold text-red-600 sm:text-4xl sm:tracking-tight lg:text-5xl">
              What Gayann Believes
            </p>
            {/* <div>
              <blockquote className="mt-4 sm:max-w-4xl mx-auto">
                <div>
                  <p className="text-base sm:text-lg text-gray-500">
                    &ldquo;Gayann DeMordaunt has earned respect as a long time champion for personal liberties of Idahoans. Personally and politically Gayann has a track record of honest effective servant leadership.&rdquo;
                  </p>
                </div>
                <footer className="mt-1 flex justify-center">
                  <div className="flex items-center space-x-3">
                    <div className="flex-shrink-0">
                      <StaticImage
                        className="h-12 w-12 rounded-full"
                        src="../images/RussFulcher.jpeg"
                        alt=""
                      />
                    </div>
                    <div className="text-base font-medium text-gray-700">- Congressman Russ Fulcher</div>
                  </div>
                </footer>
              </blockquote>
            </div> */}
          </div>
          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            {issues.map((issue) => (
              <Link to="/issues" key={issue.title} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <div className="flex-shrink-0">
                  <img className="h-48 w-full object-cover" src={issue.img} alt={issue.title} />
                </div>
                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div className="flex-1">
                    <div className="block mt-2">
                      <p className="text-xl font-semibold text-blue-900">{issue.title}</p>
                      <p className="mt-3 text-base text-gray-500">{issue.description}</p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
          <div className="pt-20 text-center">
            <Link
              to="/issues"
              className="inline-flex items-center px-5 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Gayann's Platform &rarr;
            </Link>
          </div>
        </div>
      </div>
    </div>
    <div className="">
      <div className="py-16 overflow-hidden lg:py-24">
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <svg
            className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-full"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-red-600" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={784} fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
          </svg>

          <div className="relative">
            <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div className="lg:col-start-2">
                <h3 className="text-2xl font-extrabold text-red-600 tracking-tight sm:text-3xl">
                  Meet Gayann
                </h3>
                <p className="mt-3 text-lg text-blue-900">
                  Gayann has a long history of protecting Constitutional Rights and Idaho Values for families and small businesses long before she ever put her name on a ballot. She has worked hard for all Idahoans. She knows how to get things done. This has served our community and our state in immeasurable ways.
                </p>
                <div className="mt-6 sm:flex sm:justify-center lg:justify-start">
                  <div className="space-x-8">
                    <Link
                      to="/biography"
                      className="font-medium text-red-600 text-lg hover:text-blue-700"
                    >
                      Read about background and family &rarr;
                    </Link>
                  </div>
                </div>

              </div>

              <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                <div className="px-4 sm:px-0">
                  <StaticImage
                    className="relative mx-auto rounded-lg shadow"
                    width={490}
                    src="../images/demordaunt_fam.png"
                    alt="Gayann DeMordaunt"
                  />
                </div>
              </div>
            </div>
          </div>

          <svg
            className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-3/4"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-red-600" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={784} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
          </svg>

          <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="relative">
              <h3 className="text-2xl font-extrabold text-red-600 tracking-tight sm:text-3xl">
                Defending Liberties
              </h3>
              <p className="mt-3 text-xl sm:text-2xl font-medium text-blue-900">
                &ldquo;Gayann DeMordaunt has earned respect as a long time champion for personal liberties of Idahoans. Personally and politically Gayann has a track record of honest effective servant leadership.&rdquo;
              </p>
              <div className="mt-3 ml-6 text-lg font-medium text-blue-900">- Congressman Russ Fulcher</div>
            </div>

            <div className="mt-10 relative lg:mt-0" aria-hidden="true">
              {/* Mobile SVG */}
              <svg
                className="absolute left-1/2 transform -translate-x-1/2 translate-y-24 lg:hidden"
                width={784}
                height={404}
                fill="none"
                viewBox="0 0 784 404"
              >
                <defs>
                  <pattern
                    id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-red-600" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={784} height={404} fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
              </svg>
              <div className="flex justify-end">
                <StaticImage
                  className="relative shadow-lg rounded-lg"
                  width={490}
                  src="../images/QuotePic.jpeg"
                  alt="Gayann DeMordaunt's family"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="bg-gray-100">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div>
          <h2 className="text-base font-semibold text-blue-900 uppercase tracking-wide">Get on board</h2>
          <p className="mt-2 text-3xl font-extrabold text-red-600">Proven Conservative Leadership</p>
          {/* <p className="mt-4 text-lg text-gray-500">
            Ac euismod vel sit maecenas id pellentesque eu sed consectetur. Malesuada adipiscing sagittis vel nulla nec.
          </p> */}
        </div>
        <div className="mt-12 lg:mt-0 lg:col-span-2">
          <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-3 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <CheckIcon className="absolute h-6 w-6 text-red-600" aria-hidden="true" />
                  <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                </dt>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  </Layout >
)

export default IndexPage




